import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsPOSTApi, AdsPUTApi } from "../../Constant/api";
import { slugs } from "../../Constant/slugs";
import { AlertComponent } from "../AlertComponent";
import { CustomButton } from "../CustomButtom";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const Login = useSelector((state) => state.LoginState);
  const [ID, setID] = React.useState(FetchData.id)
  const [LinkID, setLinkID] = React.useState(FetchData.meta.user_id)
  const [LinkIDName, setLinkIDName] = React.useState(FetchData.meta.user_name)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)

  const [LastName, setLastName] = React.useState(FetchData.meta.last_name)
  const [FirstName, setFirstName] = React.useState(FetchData.meta.first_name)
  const [MiddleName, setMiddleName] = React.useState(FetchData.meta.middle_name)
  const [Gender, setGender] = React.useState(FetchData.meta.gender)
  const [ContactNumber, setContactNumber] = React.useState(FetchData.meta.contact_number)
  const [PAProvince, setPAProvince] = React.useState(FetchData.meta.pa_province)
  const [PADistrict, setPADistrict] = React.useState(FetchData.meta.pa_district)
  const [PAMunicipality, setPAMunicipality] = React.useState(FetchData.meta.pa_municipality)
  const [PAWard, setPAWard] = React.useState(FetchData.meta.pa_ward)
  const [CORProvince, setCORProvince] = React.useState(FetchData.meta.cor_province)
  const [CORDistrict, setCORDistrict] = React.useState(FetchData.meta.cor_district)
  const [CORMunicipality, setCORMunicipality] = React.useState(FetchData.meta.cor_municipality)
  const [CORWard, setCORWard] = React.useState(FetchData.meta.cor_ward)
  const [QualificationDegree, setQualificationDegree] = React.useState(FetchData.meta.qualitication_degree)
  const [QualificationPercentage, setQualificationPercentage] = React.useState(FetchData.meta.qualitication_percentage)
  const [QualificationInstitute, setQualificationInstitute] = React.useState(FetchData.meta.qualitication_institution)
  const [QualificationAddress, setQualificationAddress] = React.useState(FetchData.meta.qualitication_address)
  const [CourseAbroadStudy, setCourseAbroadStudy] = React.useState(FetchData.meta.course_abroad_study)
  const [CourseTestPreparation, setCourseTestPreparation] = React.useState(FetchData.meta.course_test_preparation)
  const [CourseLanguage, setCourseLanguage] = React.useState(FetchData.meta.course_language)
  const [HowDoYouKnow, setHowDoYouKnow] = React.useState(FetchData.meta.how_do_you_know)
  const [GuardianName, setGuardianName] = React.useState(FetchData.meta.guardian_name)
  const [GuardianRelation, setGuardianRelation] = React.useState(FetchData.meta.guardian_relation)
  const [GuardianContactNumber, setGuardianContactNumber] = React.useState(FetchData.meta.guardian_contact_number)
  const [ClassTime, setClassTime] = React.useState(FetchData.meta.class_time)
  const [CardTaken, setCardTaken] = React.useState(FetchData.meta.card_taken)
  const [ClassStartDate, setClassStartDate] = React.useState(FetchData.meta.class_start_date)
  const [Total, setTotal] = React.useState(FetchData.meta.total)
  const [AmountRemaining, setAmountRemaining] = React.useState(FetchData.meta.amount_remaining)
  const [PaidAmount, setPaidAmount] = React.useState(FetchData.meta.paid)
  const [DueDate, setDueDate] = React.useState(FetchData.meta.due_date)

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "last_name": `${LastName}`,
        "first_name": `${FirstName}`,
        "middle_name": `${MiddleName}`,
        "gender": `${Gender}`,
        "contact_number": `${ContactNumber}`,
        "pa_province": `${PAProvince}`,
        "pa_district": `${PADistrict}`,
        "pa_municipality": `${PAMunicipality}`,
        "pa_ward": `${PAWard}`,
        "cor_province": `${CORProvince}`,
        "cor_district": `${CORDistrict}`,
        "cor_municipality": `${CORMunicipality}`,
        "cor_ward": `${CORWard}`,
        "qualitication_degree": `${QualificationDegree}`,
        "qualitication_percentage": `${QualificationPercentage}`,
        "qualitication_institution": `${QualificationInstitute}`,
        "qualitication_address": `${QualificationAddress}`,
        "course_abroad_study": `${CourseAbroadStudy}`,
        "course_test_preparation": `${CourseTestPreparation}`,
        "course_language": `${CourseLanguage}`,
        "how_do_you_know": `${HowDoYouKnow}`,
        "guardian_name": `${GuardianName}`,
        "guardian_relation": `${GuardianRelation}`,
        "guardian_contact_number": `${GuardianContactNumber}`,
        "class_time": `${ClassTime}`,
        "card_taken": `${CardTaken}`,
        "class_start_date": `${ClassStartDate}`,
        "total": `${Total}`,
        "amount_remaining": `${AmountRemaining}`,
        "paid": `${PaidAmount}`,
        "due_date": `${DueDate}`,
        "user_id": `${Login.userID}`,
        "user_name": `${Login.userName}`
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_admission_form)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.admission_form_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_admission_form, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Personal Details</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Last Name</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={LastName} placeholder="Last Name"
                    onChange={(event) => { setLastName(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">First Name</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={FirstName} placeholder="First Name"
                    onChange={(event) => { setFirstName(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Middle Name</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={MiddleName} placeholder="Middle Name"
                    onChange={(event) => { setMiddleName(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Gender</td>
                <td className="col-6">
                  <CustomButton setData={setGender} Data={Gender} DataValue={"Male"} />
                  <CustomButton setData={setGender} Data={Gender} DataValue={"Female"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Contact Number</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={ContactNumber} placeholder="Contact Nuber"
                    onChange={(event) => { setContactNumber(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Permament Address: Province</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={PAProvince} placeholder="Province"
                    onChange={(event) => { setPAProvince(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">District</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={PADistrict} placeholder="District"
                    onChange={(event) => { setPADistrict(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Municipality</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={PAMunicipality} placeholder="Municipality"
                    onChange={(event) => { setPAMunicipality(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Ward</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={PAWard} placeholder="Ward"
                    onChange={(event) => { setPAWard(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Corresponding: Province</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={CORProvince} placeholder="Province"
                    onChange={(event) => { setCORProvince(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">District</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={CORDistrict} placeholder="District"
                    onChange={(event) => { setCORDistrict(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Municipality</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={CORMunicipality} placeholder="Municipality"
                    onChange={(event) => { setCORMunicipality(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Ward</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={CORWard} placeholder="Ward"
                    onChange={(event) => { setCORWard(event.target.value) }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Education Details</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Qualification Degree</td>
                <td className="col-6">
                  <CustomButton setData={setQualificationDegree} Data={QualificationDegree} DataValue={"SLC"} />
                  <CustomButton setData={setQualificationDegree} Data={QualificationDegree} DataValue={"PCL/10+2"} />
                  <CustomButton setData={setQualificationDegree} Data={QualificationDegree} DataValue={"Bachelors"} />
                  <CustomButton setData={setQualificationDegree} Data={QualificationDegree} DataValue={"Masters"} />
                  <CustomButton setData={setQualificationDegree} Data={QualificationDegree} DataValue={"Others"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Percentage</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={QualificationPercentage} placeholder="Percentage"
                    onChange={(event) => { setQualificationPercentage(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Institution</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={QualificationInstitute} placeholder=""
                    onChange={(event) => { setQualificationInstitute(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Address</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={QualificationAddress} placeholder="Address"
                    onChange={(event) => { setQualificationAddress(event.target.value) }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Course Details</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Course Abroad Study</td>
                <td className="col-6">
                  <CustomButton setData={setCourseAbroadStudy} Data={CourseAbroadStudy} DataValue={"Japan"} />
                  <CustomButton setData={setCourseAbroadStudy} Data={CourseAbroadStudy} DataValue={"USA"} />
                  <CustomButton setData={setCourseAbroadStudy} Data={CourseAbroadStudy} DataValue={"Norway"} />
                  <CustomButton setData={setCourseAbroadStudy} Data={CourseAbroadStudy} DataValue={"Australia"} />
                  <CustomButton setData={setCourseAbroadStudy} Data={CourseAbroadStudy} DataValue={"Canada"} />
                  <CustomButton setData={setCourseAbroadStudy} Data={CourseAbroadStudy} DataValue={"Germany"} />
                  <CustomButton setData={setCourseAbroadStudy} Data={CourseAbroadStudy} DataValue={"UK"} />
                  <CustomButton setData={setCourseAbroadStudy} Data={CourseAbroadStudy} DataValue={"Newzerland"} />
                  <CustomButton setData={setCourseAbroadStudy} Data={CourseAbroadStudy} DataValue={"France"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Test Preparation</td>
                <td className="col-6">
                  <CustomButton setData={setCourseTestPreparation} Data={CourseTestPreparation} DataValue={"TOEFL"} />
                  <CustomButton setData={setCourseTestPreparation} Data={CourseTestPreparation} DataValue={"SAT"} />
                  <CustomButton setData={setCourseTestPreparation} Data={CourseTestPreparation} DataValue={"GMAT"} />
                  <CustomButton setData={setCourseTestPreparation} Data={CourseTestPreparation} DataValue={"PTE"} />
                  <CustomButton setData={setCourseTestPreparation} Data={CourseTestPreparation} DataValue={"IELTS"} />
                  <CustomButton setData={setCourseTestPreparation} Data={CourseTestPreparation} DataValue={"KET"} />
                  <CustomButton setData={setCourseTestPreparation} Data={CourseTestPreparation} DataValue={"GRE"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Language</td>
                <td className="col-6">
                  <CustomButton setData={setCourseLanguage} Data={CourseLanguage} DataValue={"English"} />
                  <CustomButton setData={setCourseLanguage} Data={CourseLanguage} DataValue={"German"} />
                  <CustomButton setData={setCourseLanguage} Data={CourseLanguage} DataValue={"Spanish"} />
                  <CustomButton setData={setCourseLanguage} Data={CourseLanguage} DataValue={"Chinese"} />
                  <CustomButton setData={setCourseLanguage} Data={CourseLanguage} DataValue={"Japanese"} />
                  <CustomButton setData={setCourseLanguage} Data={CourseLanguage} DataValue={"Korean"} />
                  <CustomButton setData={setCourseLanguage} Data={CourseLanguage} DataValue={"French"} />
                  <CustomButton setData={setCourseLanguage} Data={CourseLanguage} DataValue={"Others"} />

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Other Details</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">How did you know about Diplomatic</td>
                <td className="col-6">
                  <CustomButton setData={setHowDoYouKnow} Data={HowDoYouKnow} DataValue={"Respected Person"} />
                  <CustomButton setData={setHowDoYouKnow} Data={HowDoYouKnow} DataValue={"Friends"} />
                  <CustomButton setData={setHowDoYouKnow} Data={HowDoYouKnow} DataValue={"FM"} />
                  <CustomButton setData={setHowDoYouKnow} Data={HowDoYouKnow} DataValue={"Newspaper"} />
                  <CustomButton setData={setHowDoYouKnow} Data={HowDoYouKnow} DataValue={"Signboard"} />
                  <CustomButton setData={setHowDoYouKnow} Data={HowDoYouKnow} DataValue={"Others"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Guardian's Name</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={GuardianName} placeholder="Guardian's Name"
                    onChange={(event) => { setGuardianName(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Relation</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={GuardianRelation} placeholder="Relation"
                    onChange={(event) => { setGuardianRelation(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Contact Number</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={GuardianContactNumber} placeholder="Guardian Contact Number"
                    onChange={(event) => { setGuardianContactNumber(event.target.value) }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Payment and other Details</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Class Time</td>
                <td className="col-6">
                  <div>&nbsp;{ClassTime}</div>
                  {/* <input className="input-common-4"
                    value={ClassTime} placeholder="Class Time"
                    onChange={(event) => { setClassTime(event.target.value) }}
                  /> */}
                </td>
              </tr>
              <tr>
                <td className="col-3">Card Taken</td>
                <td className="col-6">
                  <div>&nbsp;{CardTaken}</div>
                  {/* <CustomButton setData={setCardTaken} Data={CardTaken} DataValue={"Yes"} />
                  <CustomButton setData={setCardTaken} Data={CardTaken} DataValue={"No"} /> */}
                </td>
              </tr>
              <tr>
                <td className="col-3">Class Start Date</td>
                <td className="col-6">
                  <div>&nbsp;{ClassStartDate}</div>
                  {/* <input className="input-common-4" type="date"
                    value={ClassStartDate} placeholder=""
                    onChange={(event) => { setClassStartDate(event.target.value) }}
                  /> */}
                </td>
              </tr>
              <tr>
                <td className="col-3">Payment Total Amount</td>
                <td className="col-6">
                  <div>&nbsp;{Total}</div>
                  {/* <input className="input-common-4"
                    value={Total} placeholder="Payment Total Amount"
                    onChange={(event) => { setTotal(event.target.value) }}
                  /> */}
                </td>
              </tr>
              <tr>
                <td className="col-3">Amount Remaining</td>
                <td className="col-6">
                  <div>&nbsp;{AmountRemaining}</div>
                  {/* <input className="input-common-4"
                    value={AmountRemaining} placeholder="Amount Remaining"
                    onChange={(event) => { setAmountRemaining(event.target.value) }}
                  /> */}
                </td>
              </tr>
              <tr>
                <td className="col-3">Paid Amount</td>
                <td className="col-6">
                  <div>&nbsp;{PaidAmount}</div>
                  {/* <input className="input-common-4"
                    value={PaidAmount} placeholder="Paid Amount"
                    onChange={(event) => { setPaidAmount(event.target.value) }}
                  /> */}
                </td>
              </tr>
              <tr>
                <td className="col-3">Due Date</td>
                <td className="col-6">
                  <div>&nbsp;{DueDate}</div>
                  {/* <input className="input-common-4" type="date"
                    value={DueDate} placeholder="Due Date"
                    onChange={(event) => { setDueDate(event.target.value) }}
                  /> */}
                </td>
              </tr>

            </tbody>
          </table>
          {/* {JSON.stringify(FetchData)} */}
          {FetchData.id == "" &&
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    EditInfo()
                  }}
                >
                  Update Form
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div>
  )

}